<template>
  <div class="box">
    <img src="@/img/platformInfo/top.png" class="top" />
    <div class="introduce">
      <img src="@/img/platformInfo/bg.png" alt="" />
      <div class="title">微企乐</div>
      <div class="nr">
        依托金融科技优势，面向企业的打造无中
        介综合金融服务平台，以帮扶小微企业，助力
        实体经济，让贷款不走弯路为使命，做到让资 金方和贷款需求方信息对称。
      </div>
    </div>
    <div class="info">
      <img src="@/img/platformInfo/info-bg.png" alt="" />
      <!-- <div class="info-title">金融产品的搜索推荐与服务平台</div>
      <div class="info-hint">
        Financial products search recommendation and serv ice platform
      </div>
      <div class="info-nr">
        最全面的金融产品和申请服务平台 打破传统居间服务模式，设立互联网
        “金融进件中心”，一站式解决中小企 业“融资难、融资慢、融资贵”的问题。
      </div> -->
    </div>
    <div class="list-box">
      <div class="list">
        <img src="@/img/platformInfo/yuan.png" class="round" />
        <div class="list-nr">
          整合金融产品，智能精准匹配，实现万中选一，让融资 更简单;
        </div>
      </div>
      <div class="list">
        <img src="@/img/platformInfo/yuan.png" class="round" />
        <div class="list-nr">
          借助金融科技，打造智能评估体系，数秒即可查询融资 额度;
        </div>
      </div>
      <div class="list">
        <img src="@/img/platformInfo/yuan.png" class="round" />
        <div class="list-nr">
          通过平台力量，减少中间环节，提高融资效率，降低融 资成本。
        </div>
      </div>
    </div>
    <div class="bottom-title">无中介助贷，开启 0收费模式</div>
    <div class="bottom-box">
      <div class="tag">
        <div class="tag-top"><img src="@/img/platformInfo/tag1.png" /></div>
        <div class="tag-bottom">优质金融产品推荐 银行平台申请办理</div>
      </div>
      <div class="tag">
        <div class="tag-top"><img src="@/img/platformInfo/tag2.png" /></div>
        <div class="tag-bottom">全程无中介助贷 0收费模式</div>
      </div>
      <div class="tag">
        <div class="tag-top"><img src="@/img/platformInfo/tag3.png" /></div>
        <div class="tag-bottom">
          打通银行和企业的最后一公里，引来信贷资金活水
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.box {
  background: #f4f5f9;
  min-height: 100vh;
  padding-bottom: 30px;
  padding-top: 60px;
  .top {
    width: 375px;
    height: 215px;
  }
  .introduce {
    width: 355px;
    height: 167px;
    position: relative;
    margin: 0 auto;
    margin-top: 15px;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .title {
      font-size: 24px;
      color: #115ff5;
      font-weight: bold;
      position: absolute;
      top: 15px;
      left: 15px;
    }
    .nr {
      width: 280px;
      font-size: 14px;
      line-height: 24px;
      color: #555555;
      position: absolute;
      left: 50%;
      margin-left: -140px;
      top: 47px;
    }
  }
  .info {
    width: 375px;
    height: 165px;
    position: relative;
    margin-top: 15px;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .info-title {
      width: 84px;
      font-size: 12px;
      color: #e9ba73;
      font-weight: bold;
      position: absolute;
      top: 30px;
      left: 24px;
    }
    .info-hint {
      width: 97px;
      font-size: 8px;
      color: #e9ba73;
      position: absolute;
      top: 88px;
      left: 24px;
    }
    .info-nr {
      width: 213px;
      color: #ffffff;
      font-size: 12px;
      line-height: 24px;
      position: absolute;
      top: 26px;
      right: 17px;
    }
  }
  .list-box {
    width: 339px;
    height: 148px;
    background: #ffffff;
    border-radius: 5px;
    margin: 0 auto;
    position: relative;
    top: -20px;
    padding-top: 16px;
    .list {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      .round {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
      .list-nr {
        width: 288px;
        color: #666666;
        font-size: 12px;
      }
    }
  }
  .bottom-title {
    width: 100%;
    text-align: center;
    color: #333333;
    font-size: 15px;
    font-weight: bold;
  }
  .bottom-box {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    .tag {
      width: 110px;
      height: 140px;
      border-radius: 5px;
      background: #ffffff;
      .tag-top {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: #f2f2f2 solid 1px;
        img {
          width: 26px;
          height: 26px;
        }
      }
      .tag-bottom {
        width: 96px;
        margin: 0 auto;
        margin-top: 25px;
        color: #666666;
        font-size: 10px;
        text-align: center;
      }
    }
  }
}
</style>
